/* sd */
.edit-profile-header {
	font-weight: 600 !important;
	margin-bottom: 35px !important;
	margin-top: 20px !important;
}

.css-13cymwt-control {
	border: none !important;
}

#form-skill {
	padding: 0 !important;
}

#update-btn {
	margin-top: 30px !important;
}

#form-card {
	display: -webkit-inline-box !important;
}

#card-form-1 {
	padding-right: 15px !important;
}

#card-form-2 {
	padding-left: 15px !important;
}

.cv-upload {
	margin-top: 20px !important;
}

.mandatory {
	color: red;
	font-size: 14px;
	top: 0.5px;
}
