.delete-question {
	margin: auto !important;
}

.question-edit {
	width: 5vh !important;
	margin-left: 10px !important;
}

#del-question {
	margin: auto auto auto 10px !important;
	width: 5vh !important;
}

#quesion-icon {
	width: 1.5em !important;
	height: 1.5em !important;
	fill: #40c7c7 !important;
	cursor: pointer !important;
}
