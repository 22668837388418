#add-question-header,
#add-question-body {
	text-align: right !important;
}

.test-answer {
	text-align: center !important;
}

.test-question {
	font-weight: 500 !important;
	text-align: left !important;
	font-size: 15px !important;
}

#test-test {
	width: 10vh !important;
	margin: auto auto 20px auto !important;
}

#question-edit {
	margin: auto !important;
	width: 5vh !important;
	text-align: right !important;
}

.question-actions {
	display: inline-flex !important;
	margin: auto !important;
}

.card-inner {
	display: inline-flex !important;
}

#edit-question-icon {
	width: 1.5em !important;
	height: 1.5em !important;
	cursor: pointer !important;
}

#question-icon {
	margin: auto !important;
}

.btn-info {
	--bs-btn-hover-color: #fff !important;
	--bs-btn-active-color: #fff !important;
}
