.mustHaveRow {
	text-align: left;
	margin: auto;
	width: auto;
}

.row-item {
	text-align: left;
	width: 45%;
}

.row-item-icons {
	text-align: right;
	width: 20%;
}

.row-item-desc {
	width: 35%;
	text-align: left;
}

.mustHaveHeaders {
	text-align: left;
}

.mustHaveHeaders {
	text-align: left;
}

.mustHaveHeader-desc {
	text-align: left;
}

.mustHaveHeaders-icons {
	text-align: right;
}

#del-icon {
	margin-left: 15px;
}

.radio-label {
	margin-left: 10px !important;
}

.check-div {
	margin-top: 10px !important;
}

#code-editor {
	width: 100% !important;
	height: 20vh !important;
}
/*  */