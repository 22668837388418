.button-sub-candidate {
	/* // */
	margin-top: 20px;
}

.test {
	background-color: aqua !important;
}

.cv {
	margin-top: 20px;
}

.cand-btn {
	margin-top: 30px;
}

#form-card {
	display: -webkit-inline-box !important;
}

#card-form-1 {
	padding-right: 15px !important;
}

#card-form-2 {
	padding-left: 15px !important;
}

#card-form-3 {
	margin-bottom: 30px;
	border: 1px solid #e6edef;
	transition: all 0.3s ease;
	letter-spacing: 0.5px;
	border-radius: 0;
	background-color: #fff;
	margin-right: 10px;
}

#form-notes {
	height: 400px;
	padding: 10px;
}

.css-13cymwt-control {
	border: none !important;
}

#form-skill-mandatory {
	padding: 0 !important;
	z-index: 999 !important;
}

#form-skill-skills {
	padding: 0 !important;
	z-index: 998 !important;
}
