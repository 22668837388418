#add-badge {
	width: 10% !important;
	margin: 30px auto 30px 30px !important;
}

#badge-table {
	border: none !important;
}

#feed-badge {
	width: fit-content !important;
	margin: 30px auto 30px 30px !important;
}
