.main-btn {
	width: 13% !important;
	background-color: rgb(9, 152, 235) !important;
	border: none !important;
	text-align: center !important;
	margin: 20px auto 0 auto !important;
}

.table-data {
	margin: auto;
	padding: auto !important;
	text-align: center !important;
}

.table-header {
	text-align: center !important;
}
/*  */