#badge-cancel {
	width: 140px !important;
}

.badge-image {
	width: 70px !important;
	height: 70px !important;
	object-fit: contain !important;
	margin-top: 20px !important;
}

#edit-btns {
	margin: 10px 10px 10px auto;
	width: fit-content !important;
}
