#summary-text {
	color: #999;
}

#job-span {
	font-weight: 600 !important;
	color: #7c7c7c !important;
}

#job-btn {
	margin: auto !important;
}
