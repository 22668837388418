#profile-header {
	color: var(--theme-default) !important;
}

.skilledup {
	margin: 50px auto auto auto !important;
	width: 250px;
	height: 100px !important;
}

.user-pfp {
	min-height: 120px !important;
	min-width: 120px !important;
	object-fit: cover !important;
	margin-top: 30px !important;
}
