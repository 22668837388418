#add-question-header,
#add-question-body {
	text-align: right !important;
}

#test-names {
	color: #495057 !important;
}

#test-name-row {
	width: 20%;
}

#test-desc-row {
	width: 80%;
}
