/**=====================
  61. React-Plugin CSS Start
==========================**/
.simple-list {
	.list-group-item {
		padding: 0;
		border: none;
		background-color: transparent;
	}
}
li.list-group-item.active {
	background-color: unset;
	border-color: unset;
	color: unset;
}
img.media {
	margin-bottom: 0 !important;
}
ul.list-group {
	display: block;
	li.list-group-item.active {
		background-color: $transparent-color;
		border-color: $transparent-color;
	}
	img {
		width: auto;
		height: auto;
	}
	i {
		margin-right: unset;
	}
}
.filter-block {
	ul {
		li {
			.form-check-input {
				border: 1px solid rgba($black, 0.25);
			}
		}
	}
}
pre.CodeMirror-line {
	background-color: transparent;
}
div.CodeMirror-cursors {
	visibility: hidden;
	position: relative;
	z-index: 3;
}
.CodeMirror-cursor {
	position: absolute;
}
.CodeMirror-measure {
	position: absolute;
	width: 100%;
	height: 0;
	overflow: hidden;
	visibility: hidden;
}
.input-group-append,
.input-group-prepend {
	display: flex;
}
.cke_bottom {
	display: none !important;
}
.editor-statusbar {
	span {
		display: inline-block;
		min-width: 4em;
		margin-left: 1em;
	}
	.words {
		&:before {
			content: "words: ";
		}
	}
	.lines {
		&:before {
			content: "lines: ";
		}
	}
}
.editor-statusbar {
	border-bottom: 1px solid $gray-60;
	padding: 8px 10px;
	font-size: 12px;
	color: #959694;
	text-align: right;
}
.CodeMirror {
	box-sizing: border-box;
	height: auto;
	border: 1px solid #ddd;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	padding: 10px;
	font: inherit;
	z-index: 0;
	word-wrap: break-word;
}
.editor-toolbar {
	position: relative;
	user-select: none;
	padding: 10px 10px;
	border-top: 1px solid $gray-60;
	border-left: 1px solid $gray-60;
	border-right: 1px solid $gray-60;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	i {
		color: #2c3e50;
	}
	&::before {
		margin-bottom: 8px;
	}
	i.separator {
		display: inline-block;
		width: 0;
		border-left: 1px solid $gray-300;
		border-right: 1px solid $white;
		color: transparent;
		text-indent: -10px;
		margin: 0 6px;
	}
	button {
		width: 30px;
	}
}
.editor-toolbar {
	.easymde-dropdown,
	button {
		background: 0 0;
		display: inline-block;
		text-align: center;
		text-decoration: none !important;
		height: 30px;
		margin: 0;
		padding: 0;
		border: 1px solid transparent;
		border-radius: 3px;
	}
}
ol.simple-list {
	.list-group-item {
		display: list-item;
	}
}
// date picker
.cal-date-widget {
	.react-datepicker {
		box-shadow: 0 4px 14px rgb(174 197 231 / 50%);
		border: none;
		padding: 8px 16px 8px;
	}
	.react-datepicker__day--outside-month {
		color: $gray-60;
	}
	.react-datepicker__day,
	.react-datepicker__month-text,
	.react-datepicker__quarter-text,
	.react-datepicker__year-text {
		&:hover {
			background-color: $primary-color;
			color: $white;
		}
	}
}
.react-datepicker__header {
	background-color: $white;
	border: none;
}
.react-datepicker__navigation {
	background-color: $primary-color;
	border-radius: 5px;
	color: $white;
	top: 18px;
	&.react-datepicker__navigation--previous {
		left: 28px;
	}
	&.react-datepicker__navigation--next {
		right: 28px;
	}
}
.react-datepicker__day--selected {
	background-color: $primary-color;
}
.react-datepicker__navigation-icon {
	top: 3px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	color: $primary-color;
	padding: 8px;
}
.react-datepicker__navigation-icon--previous {
	right: 0;
}
.react-datepicker__day-name {
	font-weight: bold;
}
.react-datepicker__week,
.react-datepicker__day {
	display: flex;
	justify-content: center;
	align-items: center;
}
.react-datepicker__navigation-icon--next,
.react-datepicker__navigation-icon--previous {
	&::before {
		top: 7px;
		left: -4px;
		border-color: $white;
	}
}
// slick
.slick-page {
	.slick-slider {
		margin: -8px 0 20px;
	}
}
.slick-slider {
	.item {
		&:focus {
			outline: none;
		}
	}
	.slick-dots {
		li {
			button {
				&:before {
					font-size: 12px;
					color: var(--theme-default);
				}
			}
			&.slick-active {
				button {
					&:before {
						color: var(--theme-default);
					}
				}
			}
		}
	}
	.slick-list {
		margin: 0 -5px;
	}
	.slick-slide {
		margin: 0 5px;
	}
}
// range slider
.rangeslider-horizontal,
.rangeslider-vertical {
	.rangeslider__fill {
		background-color: var(--theme-default) !important;
	}
	.rangeslider__handle {
		&:focus {
			outline: none;
		}
	}
}
.slider-vertical,
.vertical-height {
	.value {
		text-align: center;
	}
}
.rangeslider-vertical {
	.rangeslider__labels {
		margin: 0 0 0 32px !important;
	}
}
.input-range__slider {
	background: var(--theme-default);
	border: 1px solid var(--theme-default);
}
.input-range__track--active {
	background: var(--theme-default);
}
.input-range {
	margin-bottom: 34px;
}
// dropzone
.dzu-dropzone {
	border: 2px dashed var(--theme-default) !important;
	background: rgba($primary-color, 0.2);
	overflow: auto !important;
	display: flex;
	justify-content: center;
	align-items: center;
	.dz-message {
		.note {
			background-color: $transparent-color;
		}
	}
}
.rbc-toolbar button.rbc-active,
.rbc-toolbar button:active {
	background-color: var(--theme-default) !important;
	box-shadow: none !important;
	color: $white !important;
}
.rbc-toolbar button {
	border: 1px solid var(--theme-default) !important;
	font-size: 14px;
}
.rbc-ellipsis,
.rbc-event,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
	background-color: var(--theme-default) !important;
}
.fc-button-primary,
.fc-event,
.fc-event-dot {
	background-color: var(--theme-default) !important;
	border-color: var(--theme-default) !important;
}
.fc-event,
.fc-event-dot {
	padding: 7px !important;
	color: $white !important;
}
.dzu-inputLabel {
	color: var(--theme-default) !important;
	text-align: center;
	margin-bottom: 0;
}
.dzu-previewContainer {
	padding: 30px !important;
}
.dzu-inputLabelWithFiles {
	margin-left: 30px !important;
	background-color: var(--theme-default) !important;
	color: $white !important;
}
.dzu-submitButton {
	background-color: var(--theme-default) !important;
	&:disabled {
		background-color: #e6e6e6 !important;
	}
}
// Image Cropper
.input-cropper {
	margin-bottom: 15px;
	border: 1px solid #ced4da;
	padding: 10px;
	border-radius: 4px;
	width: auto;
	input {
		width: -webkit-fill-available;
	}
}
.crop-portion {
	margin-left: 30px;
	margin-top: 30px;
	vertical-align: baseline;
}
.cropper-view-box {
	outline-color: var(--theme-default);
	outline: 1px solid var(--theme-default);
}
.cropper-point {
	background-color: var(--theme-default);
}
// sticky
.note {
	background-color: rgba($primary-color, 0.3);
	&:nth-child(2n) {
		background: rgba($secondary-color, 0.3);
	}
}
.react-stickies-wrapper {
	.close {
		&:before,
		&:after {
			background: $transparent-color !important;
		}
	}
	.react-grid-layout {
		.react-grid-item {
			&:nth-child(1n) {
				.note {
					background: lighten($primary-color, 35%) !important;
				}
			}
			&:nth-child(2n) {
				.note {
					background: lighten($secondary-color, 35%) !important;
				}
			}
			&:nth-child(3n) {
				.note {
					background: lighten($success-color, 50%) !important;
				}
			}
			&:nth-child(4n) {
				.note {
					background: lighten($primary-color, 30%) !important;
				}
			}
			&:nth-child(5n) {
				.note {
					background: lighten($warning-color, 35%) !important;
				}
			}
			&:nth-child(6n) {
				.note {
					background: lighten($danger-color, 25%) !important;
				}
			}
		}
	}
}
// sweetalert
.lead {
	margin-bottom: 5px;
}
.swal2-container {
	.swal2-actions {
		.swal2-styled {
			font-size: 14px;
		}
	}
}
.swal2-styled {
	&.swal2-confirm {
		background-color: var(--theme-default) !important;
		&:focus {
			box-shadow: 0 0 0 1px $white, 0 0 0 3px rgba($primary-color, 0.4);
		}
	}
}
.swal2-title {
	font-size: 20px !important;
}
.swal2-progress-steps {
	.swal2-progress-step,
	.swal2-progress-step-line {
		background: var(--theme-default) !important;
	}
	.swal2-progress-step {
		&.swal2-active-progress-step {
			background: var(--theme-default) !important;
			~ .swal2-progress-step,
			~ .swal2-progress-step-line {
				background: rgba($primary-color, 0.2) !important;
			}
		}
	}
}
// datetime picker
.bootstrap-datetimepicker-widget {
	tr {
		th,
		td {
			&:active,
			&.active {
				background-color: var(--theme-default);
			}

			span {
				&:active,
				&.active {
					background-color: var(--theme-default);
				}
			}
		}
	}
}
// upload
.fileContainer {
	padding: 0 !important;
	margin: 0 !important;
	box-shadow: none !important;
	.files-gallery {
		display: flex;
		justify-content: center;
		.files-gallery-item {
			width: 40%;
			margin: 0 auto;
		}
	}
	.uploadPictureContainer {
		margin: 1% !important;
		padding: 10px;
	}
	.chooseFileButton {
		margin-top: 0 !important;
		padding: 6px 23px;
		background: $secondary-color;
		border-radius: 30px;
		color: $white;
		font-weight: 300;
		font-size: 14px;
		margin: 10px 0;
		transition: all 0.2s ease-in;
		cursor: pointer;
		outline: none;
		border: none;
	}
}
// daterange picker
.daterangepicker {
	td,
	th {
		&.available {
			&:hover {
				background-color: var(--theme-default);
			}
		}
	}
}
.nav-link {
	color: var(--theme-default);
	&:hover {
		color: var(--theme-default);
	}
}
// to-do
.todo {
	.action-box {
		&.large {
			.icon {
				.icon-trash,
				.icon-check {
					color: var(--theme-default);
				}
			}
		}
	}
	.todo-list-wrapper {
		#todo-list {
			li {
				&.completed {
					.task-container {
						.task-label {
							color: var(--theme-default);
						}
					}
				}
			}
		}
	}
	@-webkit-keyframes taskHighlighter {
		0% {
			background: rgba($primary-color, 0.5);
		}
		100% {
			background: $white;
		}
	}
	@keyframes taskHighlighter {
		0% {
			background: rgba($primary-color, 0.5);
		}
		100% {
			background: $white;
		}
	}
}
// clock
.react-clock__face {
	border: 1px solid $white !important;
}
.react-clock__mark__body,
.react-clock__hand__body {
	background-color: $white !important;
}
// scrollable page
.scrollarea {
	max-width: 800px;
	max-height: 300px;
	.scrollbar-container {
		z-index: 7 !important;
	}
}
//form wizard
ol {
	&.progtrckr {
		list-style-type: none;
		padding: 0;
		li {
			display: inline-block;
			text-align: center;
			line-height: 4.5rem;
			cursor: pointer;
			span {
				padding: 0 1.5rem;
			}
			&.progtrckr-doing {
				color: black;
				border-bottom: 4px solid #cccccc;
				&:before {
					content: "\2022";
					color: white;
					background-color: #cccccc;
					width: 1.2em;
					line-height: 1.2em;
					border-radius: 1.2em;
				}
				&:hover {
					&:before {
						color: #ff4500;
					}
				}
			}
			&.progtrckr-done {
				color: black;
				border-bottom: 4px solid #5cb85c;
				&:before {
					content: "\2713";
					color: white;
					background-color: #5cb85c;
					width: 1.2em;
					line-height: 1.2em;
					border-radius: 1.2em;
				}
				&:hover {
					&:before {
						color: #333;
					}
				}
			}
			&:after {
				content: "\00a0\00a0";
			}
			&:before {
				position: relative;
				bottom: -3.7rem;
				float: left;
				left: 50%;
			}
			&.progtrckr-todo {
				&:before {
					content: "\039F";
					color: silver;
					background-color: $transparent-color;
					width: 1.2em;
					line-height: 1.4em;
				}
				&:hover {
					&:before {
						color: #ff4500;
					}
				}
			}
		}
	}
}
//   base-typeahead
.rbt-token {
	color: var(--theme-default) !important;
	background-color: rgba($primary-color, 0.1) !important;
	display: flex;
	position: relative;
	width: fit-content;
	padding: 5px;
	+ .rbt-token {
		margin-left: 8px;
	}
	.rbt-token-remove-button {
		top: -5px !important;
	}
	.rbt-close-content {
		position: absolute;
		top: -2px !important;
	}
}
.rbt-aux {
	.rbt-close-content {
		position: absolute;
		top: 5px;
		right: 36px;
	}
}
.rbt.has-aux,
.rbt-input-multi,
.rbt-input-wrapper {
	display: flex;
}
.btn-toolbar {
	margin-bottom: -8px !important;
}
.rbt-aux {
	.rbt-close {
		margin-top: 0;
	}
}
// time-picker
.react_times_button {
	.wrapper {
		display: block;
	}
}
.time_picker_wrapper2 {
	display: flex;
	.time_picker_container {
		width: 44%;
	}
	.gap {
		padding: 0 5px;
	}
}
// toastr
.Toastify__close-button {
	font-weight: 500;
	svg {
		stroke: $white !important;
		fill: $white !important;
	}
}
.Toastify__toast--success {
	background: $primary-color !important;
	color: $white !important;
	font-weight: 500;
	svg {
		fill: transparent !important;
		stroke: $white !important;
	}
	.Toastify__progress-bar {
		background: lighten($primary-color, 35%) !important;
	}
}
.Toastify__toast--info {
	background: lighten($primary-color, 15%) !important;
	color: $white !important;
	svg {
		fill: transparent !important;
		stroke: $white !important;
	}
	.Toastify__progress-bar {
		background: lighten($primary-color, 35%) !important;
	}
}
.Toastify__toast--warning {
	background: lighten($warning-color, 15%) !important;
	color: $white !important;
	svg {
		fill: transparent !important;
		stroke: $white !important;
	}
	.Toastify__progress-bar {
		background: lighten($warning-color, 25%) !important;
	}
}
.Toastify__toast--error {
	background: lighten($danger-color, 10%) !important;
	color: $white !important;
	font-weight: 500;
	svg {
		fill: transparent !important;
		stroke: $white !important;
	}
	.Toastify__progress-bar {
		background: lighten($danger-color, 20%) !important;
	}
}
.deni-react-treeview-container {
	width: 100% !important;
	.icon-and-text {
		.text {
			.text-inner {
				color: $primary-color;
				font-size: 14px;
				.treeview-item-example-text {
					vertical-align: middle;
				}
			}
		}
	}
}
.deni-react-treeview-container.classic {
	border-color: $light-gray !important;
	.deni-react-treeview-item-container.classic {
		&:hover.select-row {
			background-color: rgba($primary-color, 0.1) !important;
			border: unset;
		}
		.checkbox {
			border-color: $primary-color !important;
		}
		.expand-button.hasChild {
			color: $primary-color !important;
		}
		.actionButton {
			margin-left: 6px;
		}
		.icon-and-text {
			&.selected {
				background: linear-gradient(
					to bottom,
					rgba($primary-color, 0.1) 0%,
					rgba($primary-color, 0.6) 100%
				) !important;
				border: unset;
			}
			&:hover:not(.select-row) {
				background-color: rgba($primary-color, 0.1) !important;
				border: unset;
				padding-left: calc(5px - 0px);
			}
			.icon {
				opacity: 0.5;
			}
		}
	}
}
.deni-react-treeview-item-container {
	.expand-button.colapsed {
		&::before {
			height: 29px;
		}
	}
}
// draggable card
.draggable-card {
	> div {
		> div {
			width: 100%;
			display: flex;
			.react-kanban-column {
				width: 100%;
			}
			.react-kanban-card {
				min-width: 100%;
				max-width: 100%;
			}
		}
	}
}
.ui-sortable {
	.react-kanban-board {
		padding: 0;
		margin: -5px;
		.react-kanban-column {
			width: 100%;
		}
	}
}
.jkanban-container {
	.custom-board {
		.kanban-drag {
			.react-kanban-column {
				&:nth-child(2) {
					div[data-rbd-drag-handle-context-id="1"] {
						background-color: $warning-color;
						~ div {
							background-color: rgba($warning-color, 0.3);
						}
					}
				}
				&:nth-child(3) {
					div[data-rbd-drag-handle-context-id="1"] {
						background-color: $secondary-color;
						~ div {
							background-color: rgba($secondary-color, 0.3);
						}
					}
				}
			}
			div[data-rbd-drag-handle-context-id="1"] {
				background-color: $primary-color;
				color: $white;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				~ div {
					background-color: rgba($primary-color, 0.3);
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
				}
				span {
					font-weight: 500;
				}
				span.badge {
					font-weight: 400;
				}
			}
		}
	}
	.kanban-container {
		display: flex;
		.kanban-board {
			width: 100%;
		}
		.kanban-item {
			.customers {
				ul.simple-list {
					li {
						background-color: $transparent-color;
						+ li {
							img {
								margin: 0;
							}
						}
					}
				}
			}
			&:focus {
				outline: none;
			}
			.kanban-box {
				border: 1px solid $light-semi-gray;
				background-color: $white !important;
				color: $theme-body-font-color;
				&:focus {
					outline: none;
				}
			}
		}
		.kanban-drag {
			width: 100%;
			.react-kanban-column-header {
				font-weight: 500;
				&:focus {
					outline: none;
				}
				> div {
					&:focus {
						outline: none;
					}
				}
			}
			> div {
				padding: 0;
				> div {
					display: flex;
					width: 100%;
					.react-kanban-column {
						width: 98%;
						background-color: rgba($primary-color, 0.1);
						border-radius: 8px;
						padding: 0;
						margin-bottom: 30px;
						> div {
							width: 100%;
							padding: 20px;
							font-size: 16px;
							border-bottom: 1px solid rgba($primary-color, 0.1);
							p {
								font-weight: 400;
							}
							> div {
								padding-bottom: 0;
							}
						}
						.react-kanban-board {
							> div {
								width: 100%;
							}
							+ .react-kanban-board {
								margin-top: 20px;
							}
						}
					}
				}
			}
		}
	}
	.react-kanban-column {
		background-color: $body-color;
		> div {
			> div {
				> div {
					display: block !important;
				}
			}
		}
		div[tabindex="0"] {
			+ div[tabindex="0"] {
				margin-top: 20px;
			}
		}
	}
}
//DragNDrop
.ui-sortable {
	.container {
		display: block;
		width: 100%;
		padding: 10px;
		background-color: var(--theme-default);
		div {
			padding: 10px;
			background-color: darken($primary-color, 5%);
			transition: opacity 0.4s ease-in-out;
			color: $white;
		}
		div {
			cursor: move;
			cursor: grab;
			cursor: -moz-grab;
			cursor: -webkit-grab;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.ex-moved {
			background-color: #e74c3c;
		}
		&.ex-over {
			background-color: var(--theme-default);
		}
		&:nth-child(odd) {
			background-color: var(--theme-default);
		}
	}
}
.gh-fork {
	position: fixed;
	top: 0;
	right: 0;
	border: 0;
}
.gu-mirror {
	padding: 10px;
	background-color: darken($primary-color, 5%);
	transition: opacity 0.4s ease-in-out;
	color: $white;
	position: fixed !important;
	margin: 0 !important;
	z-index: 9999 !important;
	opacity: 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	filter: alpha(opacity=80);
}
.gu-hide {
	display: none !important;
}
.gu-unselectable {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}
.gu-transit {
	opacity: 0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
	filter: alpha(opacity=20);
}
.wrapper {
	display: table;
}
.gu-mirror {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}
.handle {
	padding: 0 5px;
	margin-right: 5px;
	background-color: darken($primary-color, 15%);
	cursor: move;
}
// timeline
.vertical-timeline {
	&::before {
		background: $light-color;
	}
	.vertical-timeline-element {
		p {
			font-size: 13px !important;
		}
		.vertical-timeline-element-icon {
			color: $white;
		}
		&:first-child,
		&:nth-child(7) {
			.vertical-timeline-element-icon {
				background-color: var(--theme-default);
			}
		}
		&:nth-child(2) {
			.vertical-timeline-element-icon {
				background-color: var(--theme-secondary);
			}
		}
		&:nth-child(3),
		&:last-child {
			.vertical-timeline-element-icon {
				background-color: $success-color;
			}
		}
		&:nth-child(4) {
			.vertical-timeline-element-icon {
				background-color: var(--theme-default);
			}
		}
		&:nth-child(5) {
			.vertical-timeline-element-icon {
				background-color: $warning-color;
			}
		}
		&:nth-child(6) {
			.vertical-timeline-element-icon {
				background-color: $danger-color;
			}
		}
	}
}
.vertical-timeline-element-content {
	box-shadow: 1px 1px 2px 1px rgba($black, 0.08);
	background-color: $light;
	audio {
		width: 100%;
		margin-top: 15px;
	}
	.vertical-timeline-element-date {
		font-size: 14px !important;
	}
	.vertical-timeline-element-content-arrow {
		border-right: 7px solid $light-color;
	}
}
.vertical-timeline--two-columns {
	.vertical-timeline-element-content {
		.vertical-timeline-element-date {
			top: 10px !important;
		}
	}
}
.vertical-timeline--one-column {
	&:before {
		left: 28px !important;
	}
	.vertical-timeline-element-icon {
		width: 60px;
		height: 60px;
	}
	.vertical-timeline-element-content {
		margin-left: 90px;
		img {
			width: 500px;
		}
		.vertical-timeline-element-date {
			float: none;
			display: block;
		}
	}
}
// leafleft map
.leaflet-top,
.leaflet-bottom {
	z-index: 7 !important;
}
.leaflet-pane {
	z-index: 1 !important;
}
// vector map
.map-block {
	svg {
		g {
			transform-origin: 0 0;
		}
	}
}
#gmap-simple {
	svg {
		path {
			fill: var(--theme-default) !important;
		}
	}
}
#usa {
	svg {
		path {
			fill: var(--theme-secondary) !important;
		}
	}
}
#canada {
	svg {
		path {
			fill: $success-color !important;
		}
	}
}
#asia {
	svg {
		path {
			fill: $warning-color !important;
		}
	}
}
#europe {
	svg {
		path {
			fill: $danger-color !important;
		}
	}
}
#america {
	svg {
		path {
			fill: $dark-color !important;
		}
	}
}
.jvectormap-zoomin,
.jvectormap-zoomout {
	width: 20px;
	height: 20px;
}
.jvectormap-zoomin {
	bottom: 65px;
}
.jvectormap-zoomout {
	bottom: 30px;
}
.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
	position: absolute;
	border-radius: 3px;
	background: #292929;
	padding: 5px;
	color: #fff;
	cursor: pointer;
	text-align: center;
	box-sizing: content-box;
}
// rating
.rating {
	align-items: center;
	span {
		.fa {
			color: var(--theme-default);
		}
		span {
			span {
				color: var(--theme-default);
			}
		}
	}
}
@media screen and (max-width: 600px) {
	.jkanban-container {
		.kanban-container {
			.kanban-drag {
				padding: 0;
				> div {
					width: 100% !important;
					> div {
						div[data-rbd-drag-handle-context-id="1"] {
							width: 100% !important;
						}
					}
				}
			}
		}
	}
}
/**=====================
  61. React-Plugin CSS Ends
==========================**/
