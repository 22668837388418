#reg-1 {
	margin: 0 20px 0 0 !important;
	min-height: 540px !important;
}

#reg-2 {
	margin: 0 0 0 20px !important;
	min-height: 540px !important;
}

#login-media {
	display: block;
}

.login-text {
	border-top: 1px solid #ececec;
	font-weight: 400 !important;
}

.login-top {
	display: inline-flex;
}

.login-icon {
	margin-top: -20px;
	padding-bottom: 10px;
	left: 50%;
	right: 50%;
}

#login-name {
	margin: auto !important;
}

#card-con-1,
#card-con-2 {
	min-height: 600px !important;
}

@media screen and (max-width: 1040px) {
	#reg-1 {
		margin: 0 20px 0 20px !important;
	}

	#reg-2 {
		margin: 0 20px 0 20px !important;
	}
}

@media screen and (max-width: 950px) {
	#reg-1 {
		min-height: 600px !important;
	}

	#reg-2 {
		min-height: 600px !important;
	}
}

@media screen and (max-width: 650px) {
	#reg-1 {
		margin: auto auto 40px auto !important;
	}

	#reg-2 {
		margin: auto !important;
	}

	.login-card {
		height: 170vh;
	}
}

@media screen and (max-width: 550px) {
	#reg-1 {
		width: 80% !important;
	}

	#reg-2 {
		width: 80% !important;
	}
}

@media screen and (max-width: 450px) {
	#reg-label {
		margin-top: 30px !important;
	}
}

@media screen and (max-width: 350px) {
	#reg-1 {
		width: 90% !important;
	}

	#reg-2 {
		width: 90% !important;
	}
}
