#badge-btn {
	margin-right: 20px !important;
	width: 140px !important;
}

#badge-row {
	padding-top: 30px !important;
}

.is-active-check {
	margin-left: 10px !important;
}
/*  */