#job-card {
	margin: auto !important;
	width: 100% !important;
}

#job-fragment {
	margin-bottom: 30px !important;
}

#jobCard {
	margin-bottom: 50px !important;
}

.lower-section {
	margin-top: 20px;
	border-top: 1px solid #ececec;
	padding-top: 10px;
}

#danger-btn {
	background-color: d22d3d !important;
}

.form-label {
	margin-top: 15px;
	cursor: pointer;
	color: #999;
}
/*  */

.cv-upload-1 {
	margin-top: 5px !important;
}

#cv-icon {
	transition: 0.3s;
	color: var(--theme-default) !important;
}

#cv-icon:hover {
	color: #7513d0 !important;
}

.candidate-pfp {
	border-radius: 100% !important;
	width: 40px !important;
}

@media screen and (max-width: 1200px) {
	#job-detail-media {
		display: block !important;
	}
}

@media screen and (max-width: 760px) {
	.job-detail {
		margin: 10px auto auto auto !important;
	}
}
