.edit-profile-header {
	font-weight: 600 !important;
	margin-bottom: 35px !important;
	margin-top: 20px !important;
}

.css-13cymwt-control {
	border: none !important;
}

#form-skill {
	padding: 0 !important;
}

#update-btn {
	margin-top: 30px !important;
}

#form-card {
	display: -webkit-inline-box !important;
}

#card-form-1 {
	padding-right: 15px !important;
}

#card-form-2 {
	padding-left: 15px !important;
}

.cv-upload {
	margin-top: 40px !important;
}

.mandatory {
	color: red;
	font-size: 14px;
	top: 0.5px;
}

.candidate-reward-badge {
	max-height: 80px;
	max-width: 80px;
	object-fit: cover;
	margin: auto !important;
	text-align: center !important;
	justify-content: center;
}

.outer-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
}

.inner-container {
	display: flex;
	justify-content: left;
	align-items: center;
	flex-wrap: wrap;
	padding: auto !important;
}

.reward-container {
	width: 100px;
	display: block;
	justify-content: space-between;
	margin-right: 5px;
	margin-left: 5px;
}

#badge-title {
	margin-top: 5px !important;
	text-align: center !important;
}

#item-badge-row {
	justify-content: center;
	margin: auto;
}
