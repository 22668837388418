/**=====================
  83. Authentication CSS Start
==========================**/
.login-card {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	background-color: rgba($primary-color, 0.1);
	background-position: center;
	padding: 30px 0;
	.logo {
		display: block;
		margin-bottom: 30px;
		text-align: center;
	}
	.btn-showcase {
		.btn {
			line-height: 1;
			padding: 10px 15px;
			margin: 0;
			& + .btn {
				margin-left: 5px;
			}
			svg {
				height: 16px;
				vertical-align: bottom;
			}
			.icon-social-google {
				margin: 0 6px;
			}
		}
	}
	.login-main {
		width: 450px;
		padding: 40px;
		border-radius: 10px;
		box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
		margin: 0 auto;
		background-color: $white;
		.theme-form {
			h4 {
				margin-bottom: 5px;
			}
			label {
				font-size: 15px;
				letter-spacing: 0.4px;
			}
			.checkbox label::before {
				background-color: $card-body-color;
				border: 1px solid #dfdfdf;
			}
			.or {
				position: relative;
				&:before {
					content: "";
					position: absolute;
					width: 65%;
					height: 2px;
					background-color: #f3f3ff;
					top: 9px;
					z-index: 0;
					right: 0;
				}
			}
			input {
				background-color: #f3f3ff;
				transition: all 0.3s ease;
				&::-webkit-input-placeholder {
					color: $light-text;
				}
				&:hover,
				&:focus {
					border: 1px solid lighten($semi-dark, 35%);
					box-shadow: none !important;
					transition: all 0.3s ease;
				}
			}
			p {
				margin-bottom: 25px;
				font-size: 14px;
				color: $dark-gray;
			}
			.form-group {
				margin-bottom: 10px;
				position: relative;
			}
			.link {
				position: absolute;
				top: 10px;
				right: 0;
			}
		}
		&.login-tab {
			position: relative;
			border-top-left-radius: 0;
			height: auto;
			.nav-tabs {
				border-bottom: none;
				position: absolute;
				background-color: $white;
				box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
				left: -70px;
				top: 0;
				margin-bottom: 0;
				.nav-item {
					.nav-link {
						position: relative;
						img {
							filter: grayscale(100%);
							width: 20px;
							height: 20px;
							transition: 0.5s;
						}
						&:after {
							content: "";
							position: absolute;
							width: 0;
							height: 0;
							border-color: transparent;
							border-style: solid;
							top: 12px;
							border-width: 5px 0 5px 5px;
							border-left-color: lighten($primary-color, 25%);
							opacity: 0;
							transform: scale(0);
							color: #fff;
							left: 14px;
							z-index: 2;
							transition: all 0.3s ease;
						}
						span {
							position: absolute;
							right: 0;
							width: max-content;
							text-align: right;
							padding: 7px 10px;
							display: block;
							top: 0;
							border-radius: 5px;
							transform: scale(0);
							transition: all 0.3s ease;
							opacity: 0;
							color: var(--theme-default);
							background-color: lighten($primary-color, 25%);
							box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
						}
						&:hover {
							&:after {
								opacity: 1;
								transform: scale(1);
								transition: all 0.3s ease;
							}
							span {
								opacity: 1;
								right: 56px;
								transform: scale(1);
								transition: all 0.3s ease;
							}
						}
						&.active {
							img {
								filter: initial;
								transition: 0.5s;
							}
						}
					}
				}
			}
			.tab-content {
				height: 458px;
			}
			.border-tab {
				border-radius: 0;
				&.nav-tabs {
					.nav-item {
						.nav-link {
							border-bottom: none;
							border-right: 2px solid transparent;
							border-top-right-radius: 0;
							&.active,
							&.show,
							&:focus {
								border-right: 2px solid var(--theme-default);
								border-bottom: none;
							}
						}
					}
				}
			}
			.auth-content {
				text-align: center;
				img {
					height: 300px;
				}
			}
		}
	}
}
.show-hide {
	position: absolute;
	top: 67px;
	right: 20px;
	transform: translateY(-50%);
	span {
		cursor: pointer;
		font-size: 13px;
		color: var(--theme-default);
		&.show {
			&:before {
				content: "show";
			}
		}
		&:before {
			content: "hide";
		}
	}
}
.needs-validation {
	.invalid-feedback {
		color: $dark-gray;
	}
	.show-hide {
		right: 30px;
	}
	.invalid-tooltip {
		top: 10px;
		right: 10px;
	}
}
/**=====================
  83. Authentication CSS Ends
  ==========================**/
