.icon {
	font-size: 15px;
	color: rgb(92, 92, 92);
	transition: 0.5s;
	cursor: pointer;
	margin-left: 10px;
}

.star-icon {
	color: rgb(92, 92, 92);
}

.icon:hover {
	color: #00b4b4;
}

.candidate-icons {
	margin: auto 5px auto 5px !important;
	color: var(--theme-default) !important;
	transition: 0.3s;
	cursor: pointer;
	width: 500px;
}

.candidate-icons:hover {
	color: #7513d0 !important;
}

#cancel-mail {
	background-color: #62449b !important;
	border: solid 2px #62449b !important;
}

.i-container {
	margin-top: 35px;
	float: right;
}

.i-container-jobs {
	margin-top: 35px;
	float: right;
	padding-bottom: 20px;
}

.details {
	font-weight: bold;
	color: rgb(136, 136, 136);
}

.other-card {
	min-height: 30vh;
}

.logo {
	border-radius: 100%;
}

.pfp {
	border-radius: 100% !important;
}

#job-card {
	margin: auto !important;
	width: 90% !important;
}

#icon-list,
.candidate-icon-list {
	text-align: right !important;
	width: 30vh !important;
}

#icon-list {
	width: 150px !important;
}

.candidate-name {
	color: #495057 !important;
	cursor: pointer !important;
}

.p-inputtext {
	padding: 0 !important;
}

.p-dropdown-label {
	padding: 0.75rem 0.75rem !important;
}

#form-skill {
	z-index: 999 !important;
}

#skills-card-pick {
	background-color: #f8f9fa !important;
}
.p-column-header-content {
	background-color: white;
	color: black;
}
