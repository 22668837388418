.icon {
	font-size: 15px;
	color: rgb(92, 92, 92);
	transition: 0.5s;
	cursor: pointer;
	margin-left: 10px;
}

.star-icon {
	color: rgb(92, 92, 92);
}

.icon:hover {
	color: #00b4b4;
}

.i-container {
	margin-top: 35px;
	float: right;
}

.i-container-jobs {
	margin-top: 35px;
	float: right;
	padding-bottom: 20px;
}

.details {
	font-weight: bold;
	color: rgb(136, 136, 136);
}

#bulletin-card {
	min-height: 30vh !important;
}

.other-card {
	min-height: 30vh;
}

.logo {
	border-radius: 100%;
}

.pfp {
	border-radius: 100% !important;
}

#job-card {
	margin: auto !important;
	display: flex;
}
